import '../styles/common.css';

import { Link, graphql } from 'gatsby';

import Collapsible from 'react-collapsible';
import Hero from '../components/shared/hero-about';
import Layout from '../components/layout';
import PackageCta from '../components/home/package-cta';
import React from 'react';
import SEO from '../components/seo';
import ScrollAnimation from 'react-animate-on-scroll';
import SectionTitles from '../components/shared/section-titles';
import closedIcon from '../images/icons/collapsible-closed.svg';
import constants from '../constants/about';
import meta from '../constants/meta-descriptions';
import openIcon from '../images/icons/collapsible-open.svg';
import styled from 'styled-components';

export default ({ data }) => {
	return (
		<Layout activeTab={'/zashto-netsurf/'}>
			<SEO title={meta.about.title} description={meta.about.metaDescription} />

			<Hero
				heroImage={data.heroImage.childImageSharp.fluid.src}
				imageClass="hero-about"
				title={
					<>
						<h1 className="text-white mt-5">
							<span className="d-none d-md-inline-block">
								Минаваме заедно право през бурята,
								<br />
								докато другите се крият от нея
							</span>
							<span className="d-inline-block d-md-none">
								Минаваме заедно право през бурята, докато другите се крият
								от нея
							</span>
						</h1>
					</>
				}
			/>
			<div>
				<section id="about-quote" className="spacing-bottom spacing-top">
					<div className="container">
						<div className="row mt-5 d-flex justify-content-center">
							<div className="col-12 col-lg-6 order-1 order-lg-0 d-flex justify-content-center align-items-center px-lg-4">
								<div>
									<h2 className="quote">
										„Винаги правим само пионерски неща, защото
										единствено така можем да пробием при големите
										играчи на пазара. Бяхме първите и така имахме
										свободата да грешим и да творим едновременно.“
									</h2>
									<h3 className="quote">
										Цветомир Цветков,
										<br />
										основател на NetSurf пред сп. „Форбс“
									</h3>
								</div>
							</div>
							<div className="col-8 col-lg-6 text-center">
								<img
									src={data.bossImage.childImageSharp.fluid.src}
									className="img-fluid mb-2"
									alt="Цветомир Цветков"
								/>
							</div>
						</div>
					</div>
				</section>

				<section
					id="about-quote"
					className="spacing-top spacing-bottom extra-spacing"
				>
					<SectionTitles>
						<h2 className="mt-5 d-none d-sm-inline">
							Искаме като на игра да
							<br /> покоряваме невъзможното
						</h2>
						<h2
							className="mt-5 d-inline d-sm-none"
							style={{ whiteSpace: 'pre-line' }}
						>
							Искаме като на игра да покоряваме невъзможното
						</h2>
						<hr className="center" />
					</SectionTitles>

					<ScrollAnimation animateIn="fadeIn" animateOnce={true}>
						<div className="container">
							<div className="row mt-5 d-flex">
								<div className="col-12 col-lg-6 order-1 order-lg-0 d-flex justify-content-center align-items-center px-lg-4">
									<div>
										<p>
											Създадохме NetSurf, водени от детското
											влечение към неизвестното и от генетично
											заложения у нас, хората от Северозапада,
											стремеж да оборваме постоянното натякване от
											скептиците, че нещо е невъзможно. Започнахме
											като на игра преди тези вече над 20 години, а
											днес играта стана сериозна: от 20 клиенти до
											над 20 000, от dial-up до GPON интернет.
											Гордеем се, че тръгнахме от най-изостаналия
											регион в Европейския съюз, а с пътя и
											постиженията си сме главната причина сега той
											да е пример за целия свят като скорост и
											проникване на интернет.
										</p>
									</div>
								</div>
								<div className="col-12 col-lg-6 text-center">
									<img
										src={data.why3.childImageSharp.fluid.src}
										className="img-fluid mb-2"
										alt="Снимка от създаването на NetSurf"
									/>
								</div>
							</div>
						</div>
					</ScrollAnimation>

					<ScrollAnimation animateIn="fadeIn" animateOnce={true}>
						<div className="container">
							<div className="row mt-5 d-flex">
								<div className="col-12 col-lg-6 order-1 d-flex justify-content-center align-items-center px-lg-4">
									<div>
										<p>
											Защото{' '}
											<strong>пионерството е нашата кръв</strong> и
											знаем едно: вторият е просто първият сред
											губещите, а ние мразим да губим. Затова и
											бяхме сред първите доставчици в страната,
											изградили LAN-мрежи и после подземни мрежи с
											оптични кабели, затова и опровергахме всички и
											изградихме буквално на гръб над
											100-километрова безжична мрежа през Петрохан в
											снегове и бури. Чувството да стъпим на връх
											Ком и да напишем в mIRC, че чатим от там, за
											нас бе все едно да сме Нийл Армстронг във
											връзка от Луната с НАСА през 1969 година.
										</p>
									</div>
								</div>
								<div className="col-12 col-lg-6 text-center">
									<img
										src={data.why2.childImageSharp.fluid.src}
										className="img-fluid mb-2"
										alt="Снимка от създаването на NetSurf"
									/>
								</div>
							</div>
						</div>
					</ScrollAnimation>

					<ScrollAnimation animateIn="fadeIn" animateOnce={true}>
						<div className="container">
							<div className="row mt-5 d-flex">
								<div className="col-12 col-lg-6 order-1 order-lg-0 d-flex justify-content-center align-items-center px-lg-4">
									<div>
										<p>
											Жаждата да постигнем и дадем най-добрата,
											най-коректната и{' '}
											<strong>най-личната услуга</strong> ни даде и
											дава сили да устояваме на натиска на пазара и
											да не предаваме нито себе си, нито своите
											клиенти. Ето защо направихме услугата достъпна
											с предлагане на неограничен достъп до интернет
											и безплатно свързване до дома и офиса, много
											преди всички останали. Клиентът е част от
											семейството ни, а за семейството сме насреща
											във всяка секунда от денонощието и във всеки
											ден от седмицата. Създаваме условия за
											просперитет на нашия регион. Градим
											най-добрата мрежа, от най-добрите за
											най-добрите хора.
										</p>
									</div>
								</div>
								<div className="col-12 col-lg-6 text-center">
									<img
										src={data.why1.childImageSharp.fluid.src}
										className="img-fluid mb-2"
										alt="Снимка от създаването на NetSurf"
									/>
								</div>
							</div>
						</div>
					</ScrollAnimation>
				</section>

				<SectionTitles>
					<h2 className="mt-5">Всеки клиент е наш човек</h2>
					<hr className="center" />
				</SectionTitles>

				<ScrollAnimation animateIn="fadeIn" animateOnce={true}>
					<div className="container">
						<div className="w-100">
							<img
								src={data.peopleImage.childImageSharp.fluid.src}
								className="img-fluid"
								alt="Нашият екип"
							/>
						</div>
						<div className="row mt-5">
							{constants.testimonials.map(
								({ title, emoji, text }, index) => (
									<div className="col-md-6 my-4" key={index}>
										<div className="row p-0 m-0">
											<div className="col-1 p-0 m-0">{emoji}</div>
											<div className="col-11 p-0 m-0">{title}</div>
											<div className="col-1 p-0 m-0"></div>
											<div className="col-11 p-0 m-0">
												<p>{text}</p>
											</div>
										</div>
									</div>
								)
							)}
						</div>
					</div>
				</ScrollAnimation>

				<ScrollAnimation animateIn="fadeIn" animateOnce={true}>
					<FAQSection
						bgImage={data.bgImage.childImageSharp.fluid.src}
						cards={constants.faq}
					/>
				</ScrollAnimation>

				<ScrollAnimation animateIn="fadeIn" animateOnce={true}>
					<PackageCta
						bgImg={data.ctaBgImage.childImageSharp.fluid.src}
						noSpacingBottom={true}
					>
						<h2 className="my-md-5 mb-2 text-white">
							Хей, а вече можеше да си се
							<br />
							уверил колко сме добри!
						</h2>
						<Link
							to={'/za-doma/'}
							className="btn btn-primary green mt-5 mt-md-0"
						>
							Към пакетите
						</Link>
					</PackageCta>
				</ScrollAnimation>
			</div>
		</Layout>
	);
};

export const query = graphql`
	query AboutPage {
		ctaBgImage: file(relativePath: { eq: "home/cta-bg.png" }) {
			childImageSharp {
				fluid(maxHeight: 1500) {
					...GatsbyImageSharpFluid_withWebp_noBase64
				}
			}
		}
		heroImage: file(relativePath: { eq: "about/hero.png" }) {
			childImageSharp {
				fluid(maxHeight: 1852) {
					...GatsbyImageSharpFluid_withWebp_noBase64
				}
			}
		}
		bossImage: file(relativePath: { eq: "about/the-boss.png" }) {
			childImageSharp {
				fluid(maxWidth: 451) {
					...GatsbyImageSharpFluid_withWebp_noBase64
				}
			}
		}
		why1: file(relativePath: { eq: "about/why-1.png" }) {
			childImageSharp {
				fluid(maxWidth: 400) {
					...GatsbyImageSharpFluid_withWebp_noBase64
				}
			}
		}
		why2: file(relativePath: { eq: "about/why-2.png" }) {
			childImageSharp {
				fluid(maxWidth: 400) {
					...GatsbyImageSharpFluid_withWebp_noBase64
				}
			}
		}
		why3: file(relativePath: { eq: "about/why-3.png" }) {
			childImageSharp {
				fluid(maxWidth: 400) {
					...GatsbyImageSharpFluid_withWebp_noBase64
				}
			}
		}
		peopleImage: file(relativePath: { eq: "about/about-people.png" }) {
			childImageSharp {
				fluid(maxWidth: 2200) {
					...GatsbyImageSharpFluid_withWebp_noBase64
				}
			}
		}
		bgImage: file(relativePath: { eq: "home/home-reviews-bg.png" }) {
			childImageSharp {
				fluid {
					...GatsbyImageSharpFluid_withWebp_noBase64
				}
			}
		}
	}
`;

const FAQSection = ({ bgImage, cards }) => {
	const BackgroundWrapper = styled.div`
		background-image: url(${(props) => props.bgImg});
		background-size: 120% 100%;
		background-position: center;
		background-repeat: no-repeat;
	`;

	return (
		<>
			<BackgroundWrapper bgImg={bgImage}>
				<section id="about-faq" className="centering spacing-top">
					<SectionTitles>
						<h2>Нашите козове в ръкава</h2>
						<hr className="center my-4" />
					</SectionTitles>
					<div className="row d-flex mb-5">
						<div className="col-12 d-flex justify-content-center">
							<CardsSection cards={cards} />
						</div>
					</div>
				</section>
			</BackgroundWrapper>
		</>
	);
};

const CardsSection = ({ cards }) => {
	const Card = styled.div`
		background: white;
		border-radius: 8px;
		max-width: 700px;
		padding: 2rem 2rem 1rem 2rem;
		margin: 1rem 2rem 1rem 2rem;
		box-shadow: 0px 0px 36px rgba(17, 41, 70, 0.25);

		@media screen and (max-width: 576px) {
			margin: 1rem 0rem 0.75rem 0rem;
			padding: 1.5rem 1.5rem 0.5rem 1.5rem;
		}
	`;

	const CardHeader = ({ isOpen, title }) => (
		<div className="d-flex justify-content-between">
			<h3>{title}</h3>
			<button className="clear about" type="button">
				<img src={isOpen ? openIcon : closedIcon} alt="Разгърни" />
			</button>
		</div>
	);

	return (
		<div className="col-12 col-md-9 col-lg-11 col-xl-9 d-flex justify-content-center align-items-center flex-column">
			{cards.map(({ title, text }, index) => (
				<div key={title + index}>
					<Card>
						<Collapsible
							open={index === 0}
							trigger={<CardHeader isOpen={false} title={title} />}
							triggerWhenOpen={<CardHeader isOpen={true} title={title} />}
						>
							<p dangerouslySetInnerHTML={{ __html: text }} />
						</Collapsible>
					</Card>
				</div>
			))}
		</div>
	);
};

