import React from "react"

export default {
  testimonials: [
    {
      emoji: <span role="img" aria-label='emoji'>❤️</span>,
      title: <h3>Индивидуален подход</h3>,
      text: 'В бизнеса за нас всичко е лично. Всеки клиент, включително корпоративният, получава индивидуално отношение и обслужване. Връзката ни с клиентите е неразривна като интернет връзката ни.',
    },
    {
      emoji: <span role="img" aria-label='emoji'>🗒️</span>,
      title: <h3>Без скрити капани в договорите</h3>,
      text: 'Не ловим клиенти с паякови мрежи. Не вдигаме цените при още действащ договор и не го удължаваме автоматично при изтичането му. Вярваме, че прекараното заедно време ще бъде причина отново да ни се довериш. Влизаме ти в положение, когато трябва.',
    },
    {
      emoji: <span role="img" aria-label='emoji'>&#9889;</span>,
      title: <h3>Гъвкави условия за ползване на услугата</h3>,
      text: 'С нас имаш свободата да си спреш услугите за определено време и да не ги плащаш в периода, ако си надалеч на ваканция или по работа. Не оставаш на сухо с опцията „+1 ден“. Възползваш се от програмите „Лоялност“ и „Индивидуалност“, от промоциите с предплащане.',
    },
    {
      emoji: <span role="img" aria-label='emoji'>📞</span>,
      title: <h3>Денонощен отклик и 24-часов срок за решаване на проблеми</h3>,
      text: 'Другите доставчици може и да се крият и протакат с дни и дори седмици, но ние ти отговаряме на мига и проблемът ти е история в рамките на същото денонощие. Ако не успеем, те компенсираме справедливо.',
    },
    {
      emoji: <span role="img" aria-label='emoji'>🌟</span>,
      title: <h3>Удобства без брой</h3>,
      text: 'Ползваш оптичен интернет и цифрова телевизия от изцяло контролираната от нас подземна мрежа само по един кабел, на една каса, с по-добри пакетни цени. Няма вече чинии и кабелища, които да загрозяват и смаляват пространството в дома и офиса ти.',
    },
  ],

  faq: [
    {
      title: 'Непрекъсваем и светкавичен GPON интернет',
      text: 'С най-съвременната <a href="/kakvo-e-gpon/" title="Какво е GPON">GPON</a> технология вече нямаш основание за притеснение от прекъсване на услугите и повреда на любимите ти джаджи. Вдигаме скорости над 300 Mbit/s, които стават реални и възможни с новия ни 5GHz рутер.',
    },
    {
      title: 'Интерактивна цифрова телевизия',
      text: 'С нас си програмен директор на любимата си телевизия с връщане, пауза, запис и архив на съдържанието, с напомняне, подреждане на канали, дублажи на различни езици, субтитри, електрона програма и още куп благини.',
    },
    {
      title: 'Цялостни решения за бизнеса',
      text: 'Доказано добри сме в предоставянето на цялостни комуникационни решения, което включва: консултиране, проектиране и изграждане на вътрешни мрежи, както и осигуряване на оборудване, софтуер и интеграция с външни системи.',
    },
    {
      title: 'Мобилно приложение',
      text: 'С NetSurf Play можеш да гледаш мобилна телевизия навсякъде, да проверяваш всичко в своя профил при нас и да си плащаш сметката само с един „цък”.',
    },
    {
      title: 'Поглед към бъдещето със Smart Home & Smart Security',
      text: 'Съвсем скоро домът и офисът ти ще бъдат по-умни, по-сигурни и по-пестеливи, когато въведем новата ни услуга с помощта на изкуствен интелект.',
    },
  ]
}