import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
	width: 100%;
	min-height: 43rem;
	position: relative;
	display: inline-block;
	overflow: hidden;
`;

const HeroText = styled.div`
	padding-top: 8rem;
	position: relative;
	z-index: 0;

	@media (max-width: 599px) {
		padding-top: 4rem;
	}
`;

const Hero = ({ heroImage, title, imageClass, titleWrapperClasses, undertext }) => {
	return (
		<>
			<section id="about-hero">
				<div className="row w-100">
					<Container className="pt-5 text-center">
						<div className={'col-12 mx-lg-5 ' + imageClass}>
							{heroImage && (
								<img src={heroImage} alt="Бизнес решения от NetSurf" />
							)}
						</div>
						<HeroText className={titleWrapperClasses}>
							{title}
							<hr className={'center ' + (heroImage ? 'white' : '')} />
							{undertext && (
								<>
									<p className="text-white">{undertext}</p>
								</>
							)}
						</HeroText>
					</Container>
				</div>
			</section>
		</>
	);
};

Hero.defaultProps = {
	titleWrapperClasses: ''
};

export default Hero;

